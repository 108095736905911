<template>
  <div class="post">
    <resources-post v-if="loaded" ref="postForm" :formData="formData" :rules="rules" @submit="submit"
      @reset="reset"></resources-post>
  </div>
</template>

<script>
import { setOptions } from "../../utils/index";
import { ResourcesFormData } from "../../assets/data/formbuilder";
export default {
  data() {
    return {
      formData: new ResourcesFormData().build(),
      rules: {
        resourceName: { required: true, message: "请将完善此项" },
        category: { required: true, message: "请将完善此项" },
        typeId: { required: true, message: "请将完善此项" },
        filePath: { required: true, message: "请将完善此项" },
        resourceType: { required: true, message: "请将完善此项" },
        posters: { required: true, message: "请将完善此项" },
        poster: { required: true, message: "请将完善此项" },
      },
      loaded: false
    };
  },
  computed: {
    categoryList() {
      return setOptions("category_list");
    },
    isEdit() {
      return this.$route.query.id ? true : false
    }
  },
  components: {
    ResourcesPost: () => import("../../components/resources/ResourcesPost.vue"),
  },
  methods: {
    submit() {
      console.log(this.formData);
      this.$post("/resources/post", {
        ...this.formData,
      }).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);
        this.$message.success("发布成功");
        this.$router.push(`/redirect${this.$route.path}`)
      });
    },

    // 重置表单
    reset() {
      this.formData = new ResourcesFormData({ id: this.formData.id }).build();
    },

    // 获取详细信息
    getDetailData() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
      })
      this.$get("/resources/detailForEdit", { id: this.$route.query.id }).then(res => {
        loading.close();
        this.loaded = true;
        if (res.code != 200) return this.$message.error(res.message);

        this.formData = new ResourcesFormData(res.data).build();
      })
    }
  },
  mounted() {
    // 如果当前页面传递了资源id，系统则需要查询资源详情并赋值给表单
    if (this.isEdit) {
      this.getDetailData();
    } else {
      this.loaded = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
